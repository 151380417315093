/*global window, document, getSiblings ,setInterval, clearInterval,getElements,getElement,getNextSibling,getPrevSibling,setAttributes,getComputedStyle,pageDirection,console*/
/*jslint es6 */
/*=== Theme Customize ===*/
document.addEventListener('DOMContentLoaded', function () {
    'use strict';

    //===== Main Slider =====//
    var heroElement = getElements('.hero-slider');
    Array.from(heroElement).forEach(function (heroElement){
        var heroSlider = tns({
            container: heroElement,
            items: 1,
            slideBy: 'page',
            autoplay: true,
            nav:false,
            autoplayButton: false,
            autoplayButtonOutput:false,
            slideBy:1,
            startIndex:1,
            responsive: {
                600 : {items:2},
                800 : {items:3},
                1080 : {items:4},
                1360 : {items:5},
                1600 : {items:6},
                1900 : {items:7},
            }
        });
        if (heroElement.closest('.tns-outer'))heroElement.closest('.tns-outer').classList.add('hero-slider-outer');
    });

    //===== Categories Slider =====//
    var serversTabs = getElements('.servers-tabs-xx');
    Array.from(serversTabs).forEach(function (serversTabs) {
        var serversTabsSlider = tns({
            container: serversTabs,
            items: 2,
            slideBy: 'page',
            autoplay: false,
            nav:false,
            autoplayButton: false,
            autoplayButtonOutput:false,
            slideBy:1,
            autoWidth: true,
            loop:false,
        });
        if (serversTabs.closest('.tns-outer')) serversTabs.closest('.tns-outer').classList.add('servers-tabs-outer');
    });

    //===== Categories Slider =====//
    var categoriesSlider = getElements('.categories-slider');
    Array.from(categoriesSlider).forEach(function (categoriesSlider) {
        var categoriesSliders = tns({
            container: categoriesSlider,
            items: 2,
            slideBy: 'page',
            autoplay: false,
            nav:false,
            autoplayButton: false,
            autoplayButtonOutput:false,
            slideBy:1,
            startIndex:1,
            responsive: {
                520  : {items:3},
                800  : {items:5},
                1020 : {items:6},
                1300 : {items:7},
            }
        });
        if (categoriesSlider.closest('.tns-outer')) categoriesSlider.closest('.tns-outer').classList.add('categories-slider-outer');
    });

    //===== Members Slider =====//
    var membersSlider = getElements('.members-slider');
    Array.from(membersSlider).forEach(function (membersSlider) {
        var membersSliders = tns({
            container: membersSlider,
            items: 1,
            slideBy: 'page',
            autoplay: true,
            nav:false,
            autoplayButton: false,
            autoplayButtonOutput:false,
            slideBy:1,
            startIndex:1,
            responsive: {
                450  : {items:2},
                800  : {items:3},
                1020 : {items:4},
                1300 : {items:5},
            }
        });
        if (membersSlider.closest('.tns-outer')) membersSlider.closest('.tns-outer').classList.add('members-slider-outer');
    });

    //==== Grid Tiny Slider ====//
    var gridTns = getElements('.tns-outer .row');
    Array.from(gridTns).forEach(function (gridTns){
        if(!gridTns.classList.contains('gutter-small')) {
            gridTns.closest('.tns-outer').classList.add('grid-tns');
        } else {
            gridTns.closest('.tns-outer').classList.add('grid-tns gutter-small');
        }
    });

    //==== Reorder Blocks ====//
    var reorderElements = getElements('[data-order]');
    Array.from(reorderElements).forEach(element => {
        var orderNumber = element.getAttribute('data-order');
        // element.classList.add('order-'+orderNumber);
    });

    //==== Show Password ====//
    var showPassword = getElements('.show-password');
    Array.from(showPassword).forEach(element => {
        var passInput = getNextSibling(element,'input');
        element.addEventListener('click',e => {
            e.preventDefault();
            var attrType = passInput.getAttribute('type');
            if(attrType == 'password') {
                passInput.setAttribute('type','text');
            } else {
                passInput.setAttribute('type','password');
            }
        });
    });

    //==== Light Toggle ====//
    var lightToggle = getElements('.light-toggle');
    Array.from(lightToggle).forEach(element => {
        var lightOverlay = getElement('.media-overlay');
        var lightMedia = getElement('.media-player');
        element.addEventListener('click',e => {
            e.preventDefault();
            element.classList.toggle('active');
            lightMedia.classList.toggle('active');
            lightOverlay.classList.toggle('active');
        });
    });

    //====== Media Player =========//
    var playerServers = getElements('.server-link');
    Array.from(playerServers).forEach(element=>{
        element.addEventListener('click',event => {
            event.preventDefault();
            var videoUrl = element.getAttribute('data-video'),
                videoPlayer = getElement('#video-player'),
                playButton = getElement('.play-button'),
                siblings = getSiblings(element,'.server-link');
            //====== Pass the Video ======//
            videoPlayer.removeAttribute('src');
            videoPlayer.setAttribute('data-play',videoUrl);
            playButton.classList.remove('playing');
            //===== Buttons Active =====//
            Array.from(siblings).forEach(sibling => {
                sibling.classList.remove('active');
            });
            element.classList.add('active');
        });
    });

    var playVideo = getElement('.play-button');
    if(playVideo) {
        playVideo.addEventListener('click', event => {
            event.preventDefault();
            var videoPlayer = getElement('#video-player'),
                videoUrl = videoPlayer.getAttribute('data-play');
            videoPlayer.setAttribute('src',videoUrl);
            playVideo.classList.add('playing');
        });
    }
});